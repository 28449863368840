import React from 'react';

import classes from './InvoiceCard.module.css';

function InvoiceCard({ invoice, setSelected }) {
	const select = () => {
		setSelected(invoice);
	};

	return (
		<React.Fragment>
			<div className={classes.invoiceCard} onClick={select}>
				<p>{new Date(invoice.documetDate).toLocaleDateString()}</p>
				<p>{invoice.proFormaNo}</p>
				<p>{Number(invoice.amount).toLocaleString()}</p>
			</div>
		</React.Fragment>
	);
}

export default InvoiceCard;
