import React, { useState, useEffect, useRef } from 'react';

import classes from './InvoiceForm.module.css';

import Backdrop from '../../../../../../utils/Backdrop';
import IconButton from '../../../../../../utils/IconButton';

function InvoiceForm({ cID, invoice, saveInvoice, clear }) {
	const refProFormaNo = useRef(invoice?.proFormaNo || null);
	const refInvoiceNo = useRef(invoice?.invoiceNo || null);
	const refDocumetDate = useRef(invoice?.documetDate || null);
	const refAmount = useRef(invoice?.amount || null);
	const refRivalsa = useRef(invoice?.rivalsa || null);
	const refVat = useRef(invoice?.vat || null);
	const refPaid = useRef(invoice?.paid || null);

	useEffect(() => {
		if (invoice) {
			refProFormaNo.current.value = invoice?.proFormaNo;
			refInvoiceNo.current.value = invoice?.invoiceNo;
			refDocumetDate.current.value = new Date(invoice?.documetDate)
				.toISOString()
				.split('T')[0];
			refAmount.current.value = Number(invoice?.amount);
			refRivalsa.current.value = Number(invoice?.rivalsa);
			refVat.current.value = Number(invoice?.vat);
			refPaid.current.checked = Boolean(invoice?.paid);
		}
	}, []);

	const [saveEnabled, setSaveEnabled] = useState(false);

	const handleEnableSave = () => {
		if (
			refProFormaNo?.current?.value &&
			refDocumetDate?.current?.value &&
			refAmount?.current?.value
		) {
			setSaveEnabled(true);
		}

		if (refAmount?.current?.value) {
			refRivalsa.current.value =
				Math.round(refAmount.current.value * 0.04 * 100) / 100;
			refVat.current.value =
				Math.round(
					(Number(refRivalsa.current.value) + Number(refAmount.current.value)) *
						0.22 *
						100
				) / 100;
		}
	};

	const postData = () => {
		const nInvoice = invoice ? { ...invoice, edit: true } : {};

		if (!invoice) {
			nInvoice.refContact = cID;
		}

		if (nInvoice?.proFormaNo !== refProFormaNo.current.value) {
			nInvoice.proFormaNo = refProFormaNo.current.value;
		}
		if (nInvoice?.invoiceNo !== refInvoiceNo.current.value) {
			nInvoice.invoiceNo = refInvoiceNo.current.value;
		}
		if (nInvoice?.documetDate !== refDocumetDate.current.value) {
			nInvoice.documetDate = refDocumetDate.current.value;
		}
		if (nInvoice?.amount !== refAmount.current.value) {
			nInvoice.amount = refAmount.current.value;
		}
		if (nInvoice?.rivalsa !== refRivalsa.current.value) {
			nInvoice.rivalsa = refRivalsa.current.value;
		}
		if (nInvoice?.vat !== refVat.current.value) {
			nInvoice.vat = refVat.current.value;
		}
		if (nInvoice?.paid !== refPaid.current.value) {
			nInvoice.paid = refPaid.current.checked;
		}

		saveInvoice(nInvoice);

		clear();
	};

	return (
		<React.Fragment>
			<Backdrop onClick={clear} />
			<div className={classes.wrapper}>
				{invoice ? 'Modifica dati fattura' : 'Crea nuova fattura'}
				<div className={classes.form}>
					<label className={classes.labelWrap}>
						Numero Pro Forma
						<input
							ref={refProFormaNo}
							className={classes.input}
							onChange={handleEnableSave}
						/>
					</label>
					<label className={classes.labelWrap}>
						Numero Fattura
						<input
							ref={refInvoiceNo}
							className={classes.input}
							onChange={handleEnableSave}
						/>
					</label>
					<label className={classes.labelWrap}>
						Data
						<input
							ref={refDocumetDate}
							className={classes.input}
							type='date'
							onChange={handleEnableSave}
						/>
					</label>
					<label className={classes.labelWrap}>
						Importo €
						<input
							ref={refAmount}
							className={`${classes.input} ${classes.number}`}
							type='number'
							step='0.01'
							onChange={handleEnableSave}
						/>
					</label>
					<label className={classes.labelWrap}>
						Rivalsa €
						<input
							ref={refRivalsa}
							className={`${classes.input} ${classes.number}`}
							type='number'
							step='0.01'
							onChange={handleEnableSave}
						/>
					</label>
					<label className={classes.labelWrap}>
						Iva €
						<input
							ref={refVat}
							className={`${classes.input} ${classes.number}`}
							type='number'
							step='0.01'
							onChange={handleEnableSave}
						/>
					</label>
					<label className={`${classes.labelWrap} ${classes.cbWrap}`}>
						Incassato
						<input
							ref={refPaid}
							className={`${classes.input} ${classes.checkbox}`}
							type='checkbox'
							onChange={handleEnableSave}
						/>
					</label>
				</div>
				<div className={classes.buttons}>
					<IconButton
						text={'save'}
						style={{ color: saveEnabled ? 'var(--ONGOING)' : 'gray' }}
						action={saveEnabled ? postData : () => {}}
					/>
					<IconButton
						text={'close'}
						style={{ color: 'var(--ABORTED)' }}
						action={clear}
					/>
				</div>
			</div>
		</React.Fragment>
	);
}

export default InvoiceForm;
